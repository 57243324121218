import { lazy } from "react";

// Component Wrapper
const cw = (Component) => {
  return <Component />;
};



const allRoutes = [
  {
    path: "/",
    name: "home",
    element: cw(lazy(() => import("../pages/home"))),
  },
  {
    path: "/words",
    name: "words.index",
    element: cw(lazy(() => import("../pages/words"))),
  },
  {
    path: "/words/:count",
    name: "words.show",
    element: cw(lazy(() => import("../pages/words"))),
  },
  {
    path: "/pages",
    name: "pages.index",
    element: cw(lazy(() => import("../pages/pages"))),
  },
  {
    path: "/pages/:count",
    name: "pages.show",
    element: cw(lazy(() => import("../pages/pages"))),
  },
  {
    path: "/facts",
    name: "facts",
    element: cw(lazy(() => import("../pages/facts"))),
  },
  
  {
    path: "/speedtest",
    name: "speedtest",
    element: cw(lazy(() => import("../pages/speedtest"))),
  },
];

export default allRoutes;