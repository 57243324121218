import { Suspense } from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";

const Layout = ({ children }) => {
    return (
        <>
            <div className="navbar bg-base-300">
                <div className="flex-1 navbar-start px-2">
                    <label className="grid cursor-pointer place-items-center">
                        <input
                            type="checkbox"
                            value="forest"
                            className="toggle theme-controller bg-base-content col-span-2 col-start-1 row-start-1" />
                        <svg
                            className="stroke-base-100 fill-base-100 col-start-1 row-start-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <circle cx="12" cy="12" r="5" />
                            <path
                                d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
                        </svg>
                        <svg
                            className="stroke-base-100 fill-base-100 col-start-2 row-start-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                        </svg>
                    </label>
                </div>
                <div className="navbar-center">
                    <Link to={routes.home} className="btn btn-ghost text-2xl font-serif	">📖 Leserechner</Link>
                </div>
                <div className="flex flex-1 navbar-end px-2">
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn m-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className="inline-block h-5 w-5 stroke-current">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
                            </svg>
                        </div>
                        <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-max p-2 shadow">
                            <li>
                                <Link to={routes.home}>
                                    Wie viele Bücher kannst du in einem Jahr lesen? 📕
                                </Link>
                            </li>
                            <li>
                                <Link to={routes.words.index}>
                                    Wie lange dauert, es 100 Wörter zu lesen? 🔤
                                </Link>
                            </li>
                            <li>
                                <Link to={routes.pages.index}>
                                    Wie lange dauert, es 300 Seiten zu lesen? 📖
                                </Link>
                            </li>
                            <li>
                                <Link to={routes.speedtest}>
                                    Finde deine Lesegeschwindigkeit heraus! 🏎️
                                </Link>
                            </li>
                            <li>
                                <Link to={routes.facts}>
                                    Fakten über bekannte Bücher 💡
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Suspense>{children}</Suspense>

            <div className="mb-20">

            </div>

        </>
    );
};

export default Layout;
